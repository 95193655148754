import { Badge, Button, Spinner } from "flowbite-react";
import React, { useRef, useState } from "react";
import { GoogleIcn } from "../../layouts/icons";
import LoginAuth from "../../services/authFirebase/loginAuth";
import { useNavigate } from "react-router-dom";
import { CustomModal } from "../../layouts/customModal";

export const LoginModal = (props) => {
  let navigate = useNavigate();

  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = () => {
    LoginAuth(navigate, window.location.pathname, setStatus, setIsLoading);
  };

  const modalRef = useRef(null);

  return (
    <CustomModal
      btnText={props.btnText}
      customBtn={props.customBtn}
      isLoading={isLoading}
      modalRef={modalRef}
      status={status}
    >
      {status ? (
        status === "auth/internal-error" ? (
          <Badge color="failure">Unauthorized Email</Badge>
        ) : (
          <Badge color="failure">{status}</Badge>
        )
      ) : (
        ""
      )}

      <div className="space-y-6 mt-2 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
          Sign in Required
        </h3>

        <div className="w-full google-btn">
          <Button onClick={handleLogin} disabled={isLoading ? true : false}>
            {isLoading ? (
              <span className="inline-flex items-center justify-center">
                <Spinner aria-label="spinner" />
                <span className="pl-3">Loading...</span>
              </span>
            ) : (
              <span className="inline-flex items-center justify-center">
                <GoogleIcn />
                Continue with google
              </span>
            )}
          </Button>
        </div>
        <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
          To access the tool you need to sign in with your GO Markets Gmail
          account.
        </div>
        <button
          className={`text-sm font-semibold  ${
            isLoading ? "text-gray-400" : ""
          }`}
          onClick={() => modalRef.current()}
          disabled={isLoading}
        >
          Cancel
        </button>
      </div>
    </CustomModal>
  );
};
