import { Section } from "../../layouts/section";
import Options from "./options";

export const Content = () => {
  return (
    <div className="container">
      <Section>
        <div className="justify-center text-center lg:text-start lg:justify-start px-1 sm:px-0 ">
          <h2 className="block font-display mb-4 text-3xl font-bold tracking-tight text-main sm:text-5xl/snug">
            Get your request into production
          </h2>
          <p className="block font-display font-normal text-[1.375rem] leading-8 text-main">
            Use our teams request form to brief in your next project
          </p>
        </div>

        <div className="octagon flex items-center" >
          <Options />
        </div>
      </Section>
    </div>
  );
};
