import { Content } from "../components/home/content";
import { SiteFooter } from "../layouts/footer";
import { HeaderNav } from "../layouts/headerNav";
import { Hero } from "../layouts/hero";

function Main() {
  return (
    <>
      <HeaderNav />
      <div className="main">
        <Hero
          title={"Solve the problem. Write the code."}
          desc={
            <>
              GO Markets Dev makes it easier for teams to achieve their collective
              goals and stay informed with updates across our products
            </>
          }
          height={"h-[33rem]"}
          logoWidth={"w-10/12"}
        />
        <Content />
        <SiteFooter />
      </div>
    </>
  );
}

export default Main;
