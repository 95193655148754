import { signOut } from "firebase/auth";
import Cookies from "js-cookie";
import firebaseInit from "../../firebase";
/**
 * Logout current user.
 *
 * Note: This API is requires firebase SDK setup on the project.
 *
 */
const LogoutAuth = async (navigate, navPath) => {
  const auth = firebaseInit.auth;

  await signOut(auth)
    .then(() => {
      //clear localstorage
      localStorage.clear();
      Cookies.remove("token");
      Cookies.remove("userId");
      Cookies.remove("profImg");

      if (navigate !== undefined && navPath !== undefined) {
        navigate(navPath, { replace: true });
      }
    })
    .catch((error) => {
      // console.log(error);
    });
};

export default LogoutAuth;
