import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Cookies from "js-cookie";
import firebaseInit from "../../firebase";

/**
 * Validates users' GO Markets gmail credentials to allow access.
 *
 * Note: This API is requires firebase SDK setup on the project.
 *
 */
const LoginAuth = async (navigate, navPath, setStatus, setIsLoading) => {
  const auth = firebaseInit.auth;
  const provider = new GoogleAuthProvider();
  setIsLoading(true);
  await signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;

      Cookies.set("token", result._tokenResponse.idToken, {
        secure: true,
        sameSite: "strict",
      });
      Cookies.set("userId", user.uid, {
        secure: true,
        sameSite: "strict",
      });

      localStorage.setItem("userName", user.displayName);
      localStorage.setItem("userEmail", user.email);
      Cookies.set("profImg", user.photoURL, {
        secure: true,
        sameSite: "strict",
      });

      navigate(navPath);
      setIsLoading(false);
      // console.log(result);
    })
    .catch((error) => {
      setIsLoading(false);
      // Handle Errors here.
      const errorCode = error.code;
      setStatus(errorCode);
      // console.log(errorCode);
    });
};

export default LoginAuth;
