import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function useHandleModal(initialValue) {
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(initialValue);

  const closeModal = () => {
    setIsOpen(false);
    navigate("/", { replace: true });
  };
  const openModal = () => {
    setIsOpen(true);
  };

  return {
    isOpen,
    closeModal,
    openModal,
  };
}
