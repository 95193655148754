import { useRef } from "react";
import { Console } from "./console";
import useScreenObserver from "../hooks/useScreenObserver";

export const Hero = (props) => {
  const ref = useRef();
  const screenObserve = useScreenObserver(ref);

  return (
    <div className={`App-hero ${props.height}`} ref={ref}>
      <div className="container">
        <div className="max-w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-16 sm:flex sm:items-center sm:justify-between">
          <div className="sm:w-1/2 w-full md:px-10 xl:p-0">
            <h2 className="block mb-4 text-3xl font-bold tracking-tight text-secondary sm:text-5xl/snug font-display">
              {props.title}
            </h2>
            <p className="block">{props.desc}</p>
          </div>
          <div className="flex justify-center sm:justify-end lg:mt-0 sm:w-1/2 w-full lg:flex-shrink-0">
            <div className="hero_wrapper w-full box_fade order-last lg:order-none hidden sm:block">
              <div className="text-left mt-3 text-base  sm:text-lg sm:max-w-xl lg:max-w-lg sm:mx-auto sm:mt-40 sm:mb-40 md:text-xl">
                <div className="editor_handlebar">
                  <span className="editor_handlebar_buttons"></span>
                </div>
                <div className="editor_content">
                  {screenObserve ? (
                    <Console text={"Stay updated on all projects..."} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
