import { useState, useEffect, useRef } from "react";

export const TypeWriter = (text) => {
  const index = useRef(0);

  const [currentText, setCurrentText] = useState("");

  useEffect(() => {
    index.current = 0;
    setCurrentText("");
  }, [text]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentText(currentText + text.charAt(index.current));
      index.current += 1;
    }, 100);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentText, text]);

  return { currentText, index };
};
