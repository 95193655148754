export const User = () => {
  return (
    <svg
      className="absolute -left-1 w-10 h-10 text-gray-400"
      fill="curreantColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const GoogleIcn = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className="mr-2 -ml-1 w-4 h-4"
    >
      {" "}
      <g>
        <path
          fill="#EA4335"
          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
        ></path>
        <path
          fill="#4285F4"
          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
        ></path>
        <path
          fill="#FBBC05"
          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
        ></path>
        <path
          fill="#34A853"
          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
        ></path>
        <path fill="none" d="M0 0h48v48H0z"></path>
      </g>
    </svg>
  );
};

export const ArrowRight = () => {
  return (
    <svg
      className="ml-2 mr-1 h-4 w-4"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const Upload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="animate-bounce w-6 h-6 text-primary fill-green-100"
    >
      <path
        strokeLinecap="round"
        strokeinejoin="round"
        d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

export const File = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6 text-primary fill-green-100"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
      />
    </svg>
  );
};

export const ArrowBox = (props) => {
  return (
    <svg
      viewBox="0 0 23 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={props.class}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94376 20.0938C8.94376 20.301 9.02607 20.4997 9.17258 20.6462C9.3191 20.7927 9.51781 20.875 9.72501 20.875H20.0938C20.7154 20.875 21.3115 20.6281 21.751 20.1885C22.1906 19.749 22.4375 19.1529 22.4375 18.5312V2.90625C22.4375 2.28465 22.1906 1.68851 21.751 1.24897C21.3115 0.80943 20.7154 0.5625 20.0938 0.5625H4.46876C3.84716 0.5625 3.25102 0.80943 2.81148 1.24897C2.37194 1.68851 2.12501 2.28465 2.12501 2.90625V13.275C2.12501 13.4822 2.20732 13.6809 2.35383 13.8274C2.50035 13.9739 2.69906 14.0562 2.90626 14.0562C3.11346 14.0562 3.31217 13.9739 3.45869 13.8274C3.6052 13.6809 3.68751 13.4822 3.68751 13.275V2.90625C3.68751 2.69905 3.76982 2.50034 3.91633 2.35382C4.06285 2.20731 4.26156 2.125 4.46876 2.125H20.0938C20.301 2.125 20.4997 2.20731 20.6462 2.35382C20.7927 2.50034 20.875 2.69905 20.875 2.90625V18.5312C20.875 18.7385 20.7927 18.9372 20.6462 19.0837C20.4997 19.2302 20.301 19.3125 20.0938 19.3125H9.72501C9.51781 19.3125 9.3191 19.3948 9.17258 19.5413C9.02607 19.6878 8.94376 19.8865 8.94376 20.0938Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1875 7.59375C16.1875 7.38655 16.1052 7.18784 15.9587 7.04132C15.8122 6.89481 15.6135 6.8125 15.4063 6.8125H7.59376C7.38656 6.8125 7.18785 6.89481 7.04133 7.04132C6.89482 7.18784 6.81251 7.38655 6.81251 7.59375C6.81251 7.80095 6.89482 7.99966 7.04133 8.14618C7.18785 8.29269 7.38656 8.375 7.59376 8.375H13.5203L0.790635 21.1031C0.643938 21.2498 0.561523 21.4488 0.561523 21.6562C0.561523 21.8637 0.643938 22.0627 0.790635 22.2094C0.937333 22.3561 1.1363 22.4385 1.34376 22.4385C1.55122 22.4385 1.75019 22.3561 1.89689 22.2094L14.625 9.47969V15.4062C14.625 15.6135 14.7073 15.8122 14.8538 15.9587C15.0003 16.1052 15.1991 16.1875 15.4063 16.1875C15.6135 16.1875 15.8122 16.1052 15.9587 15.9587C16.1052 15.8122 16.1875 15.6135 16.1875 15.4062V7.59375Z"
      />
    </svg>
  );
};

export const Pen = () => {
  return (
    <svg
      viewBox="0 0 20 31"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.11503 1.50001C4.11488 1.38375 4.13767 1.2686 4.18209 1.16116C4.22652 1.05372 4.2917 0.956099 4.37391 0.87389C4.45612 0.791681 4.55374 0.726499 4.66118 0.682075C4.76862 0.637652 4.88376 0.61486 5.00003 0.615007L15 0.615005C15.1163 0.614858 15.2314 0.637649 15.3389 0.682073C15.4463 0.726496 15.5439 0.791679 15.6261 0.873888C15.7084 0.956097 15.7735 1.05372 15.818 1.16116C15.8624 1.2686 15.8852 1.38374 15.885 1.50001L15.885 6.25501L18.99 11.4325C19.2266 11.8266 19.3566 12.2755 19.3673 12.735C19.378 13.1946 19.2691 13.649 19.0513 14.0538L10 30.865L0.947526 14.0525C0.730123 13.6481 0.621472 13.1941 0.632208 12.735C0.642945 12.276 0.772701 11.8276 1.00878 11.4338L4.11503 6.25751L4.11503 1.50001ZM5.50003 7.38501L2.52503 12.3425C2.44602 12.4741 2.40263 12.6239 2.39912 12.7774C2.39561 12.9308 2.43211 13.0825 2.50503 13.2175L9.99878 27.1363L17.495 13.215C17.5671 13.0803 17.603 12.9293 17.5993 12.7766C17.5955 12.6239 17.5523 12.4748 17.4738 12.3438L14.4988 7.38376L5.50003 7.38501Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.075 26.995L11.25 15.25C11.4973 15.0028 11.6656 14.6878 11.7338 14.3449C11.802 14.002 11.767 13.6465 11.6332 13.3235C11.4994 13.0005 11.2729 12.7244 10.9821 12.5302C10.6914 12.3359 10.3497 12.2322 10 12.2322C9.6504 12.2322 9.30862 12.3359 9.01791 12.5302C8.7272 12.7244 8.50062 13.0005 8.36683 13.3235C8.23303 13.6465 8.19802 14.002 8.26623 14.3449C8.33444 14.6878 8.5028 15.0028 8.75003 15.25L9.92503 26.995L10 27.135L10.075 26.995Z"
      />
    </svg>
  );
};

export const DevConsole = () => {
  return (
    <svg
      viewBox="0 0 30 27"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
    >
      <path d="M2.42417 3.17417C2.77581 2.82254 3.25272 2.625 3.75 2.625H24.375C24.8723 2.625 25.3492 2.82254 25.7008 3.17417C26.0525 3.52581 26.25 4.00272 26.25 4.5V12C26.25 12.2486 26.3488 12.4871 26.5246 12.6629C26.7004 12.8387 26.9389 12.9375 27.1875 12.9375C27.4361 12.9375 27.6746 12.8387 27.8504 12.6629C28.0262 12.4871 28.125 12.2486 28.125 12V4.5C28.125 3.50544 27.7299 2.55161 27.0266 1.84835C26.3234 1.14509 25.3696 0.75 24.375 0.75H3.75C2.75544 0.75 1.80161 1.14509 1.09835 1.84835C0.395088 2.55161 0 3.50544 0 4.5V19.5C0 20.4946 0.395088 21.4484 1.09835 22.1516C1.80161 22.8549 2.75544 23.25 3.75 23.25H14.0625C14.3111 23.25 14.5496 23.1512 14.7254 22.9754C14.9012 22.7996 15 22.5611 15 22.3125C15 22.0639 14.9012 21.8254 14.7254 21.6496C14.5496 21.4738 14.3111 21.375 14.0625 21.375H3.75C3.25272 21.375 2.77581 21.1775 2.42417 20.8258C2.07254 20.4742 1.875 19.9973 1.875 19.5V4.5C1.875 4.00272 2.07254 3.52581 2.42417 3.17417Z" />
      <path d="M6.2032 6.44499C6.08931 6.49225 5.98585 6.56152 5.89877 6.64882C5.81146 6.73591 5.74219 6.83936 5.69493 6.95326C5.64767 7.06716 5.62334 7.18926 5.62334 7.31257C5.62334 7.43589 5.64767 7.55799 5.69493 7.67189C5.74219 7.78578 5.81146 7.88924 5.89877 7.97632L8.04939 10.1251L5.89877 12.2738C5.72273 12.4499 5.62383 12.6886 5.62383 12.9376C5.62383 13.1865 5.72273 13.4253 5.89877 13.6013C6.0748 13.7774 6.31356 13.8763 6.56252 13.8763C6.81147 13.8763 7.05023 13.7774 7.22627 13.6013L9.70689 11.1188C9.97024 10.8552 10.1182 10.4977 10.1182 10.1251C10.1182 9.75242 9.97024 9.395 9.70689 9.13132L7.22627 6.64882C7.13918 6.56152 7.03573 6.49225 6.92183 6.44499C6.80793 6.39772 6.68583 6.3734 6.56252 6.3734C6.4392 6.3734 6.3171 6.39772 6.2032 6.44499Z" />
      <path d="M10.5871 13.2122C10.4113 13.388 10.3125 13.6264 10.3125 13.8751C10.3125 14.1237 10.4113 14.3622 10.5871 14.538C10.7629 14.7138 11.0014 14.8126 11.25 14.8126H15C15.2487 14.8126 15.4871 14.7138 15.6629 14.538C15.8387 14.3622 15.9375 14.1237 15.9375 13.8751C15.9375 13.6264 15.8387 13.388 15.6629 13.2122C15.4871 13.0363 15.2487 12.9376 15 12.9376H11.25C11.0014 12.9376 10.7629 13.0363 10.5871 13.2122Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0779 25.078C29.3086 23.8472 30 22.1781 30 20.4376C30 18.6971 29.3086 17.0279 28.0779 15.7972C26.8472 14.5665 25.178 13.8751 23.4375 13.8751C21.697 13.8751 20.0278 14.5665 18.7971 15.7972C17.5664 17.0279 16.875 18.6971 16.875 20.4376C16.875 22.1781 17.5664 23.8472 18.7971 25.078C20.0278 26.3087 21.697 27.0001 23.4375 27.0001C25.178 27.0001 26.8472 26.3087 28.0779 25.078ZM22.7746 16.9622C22.9504 16.7863 23.1889 16.6876 23.4375 16.6876C23.6862 16.6876 23.9246 16.7863 24.1004 16.9622C24.2762 17.138 24.375 17.3764 24.375 17.6251V19.5001H26.25C26.4987 19.5001 26.7371 19.5988 26.9129 19.7747C27.0887 19.9505 27.1875 20.1889 27.1875 20.4376C27.1875 20.6862 27.0887 20.9247 26.9129 21.1005C26.7371 21.2763 26.4987 21.3751 26.25 21.3751H24.375V23.2501C24.375 23.4987 24.2762 23.7372 24.1004 23.913C23.9246 24.0888 23.6862 24.1876 23.4375 24.1876C23.1889 24.1876 22.9504 24.0888 22.7746 23.913C22.5988 23.7372 22.5 23.4987 22.5 23.2501V21.3751H20.625C20.3764 21.3751 20.1379 21.2763 19.9621 21.1005C19.7863 20.9247 19.6875 20.6862 19.6875 20.4376C19.6875 20.1889 19.7863 19.9505 19.9621 19.7747C20.1379 19.5988 20.3764 19.5001 20.625 19.5001H22.5V17.6251C22.5 17.3764 22.5988 17.138 22.7746 16.9622Z"
      />
    </svg>
  );
};

export const Envalope = () => {
  return (
    <svg
      viewBox="0 0 30 27"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.09835 1.84835C1.80161 1.14509 2.75544 0.75 3.75 0.75H26.25C27.2446 0.75 28.1984 1.14509 28.9016 1.84835C29.6049 2.55161 30 3.50544 30 4.5V12.9375C30 13.1861 29.9012 13.4246 29.7254 13.6004C29.5496 13.7762 29.3111 13.875 29.0625 13.875C28.8139 13.875 28.5754 13.7762 28.3996 13.6004C28.2238 13.4246 28.125 13.1861 28.125 12.9375V7.09312L15 14.9681L12.5138 13.4775L1.93875 19.9856C2.04565 20.3843 2.28109 20.7365 2.60857 20.9877C2.93605 21.2389 3.33727 21.375 3.75 21.375H14.0625C14.3111 21.375 14.5496 21.4738 14.7254 21.6496C14.9012 21.8254 15 22.0639 15 22.3125C15 22.5611 14.9012 22.7996 14.7254 22.9754C14.5496 23.1512 14.3111 23.25 14.0625 23.25H3.75C2.75868 23.25 1.80768 22.8575 1.10496 22.1583C0.402232 21.4591 0.00495661 20.5101 0 19.5188V4.5C0 3.50544 0.395088 2.55161 1.09835 1.84835ZM1.875 17.8219L10.7025 12.39L1.875 7.09312V17.8219ZM1.875 4.5V4.90688L15 12.7819L28.125 4.90688V4.5C28.125 4.00272 27.9275 3.52581 27.5758 3.17417C27.2242 2.82254 26.7473 2.625 26.25 2.625H3.75C3.25272 2.625 2.77581 2.82254 2.42417 3.17417C2.07254 3.52581 1.875 4.00272 1.875 4.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0779 25.0779C29.3086 23.8472 30 22.178 30 20.4375C30 18.697 29.3086 17.0278 28.0779 15.7971C26.8472 14.5664 25.178 13.875 23.4375 13.875C21.697 13.875 20.0278 14.5664 18.7971 15.7971C17.5664 17.0278 16.875 18.697 16.875 20.4375C16.875 22.178 17.5664 23.8472 18.7971 25.0779C20.0278 26.3086 21.697 27 23.4375 27C25.178 27 26.8472 26.3086 28.0779 25.0779ZM22.7746 16.9621C22.9504 16.7863 23.1889 16.6875 23.4375 16.6875C23.6861 16.6875 23.9246 16.7863 24.1004 16.9621C24.2762 17.1379 24.375 17.3764 24.375 17.625V19.5H26.25C26.4986 19.5 26.7371 19.5988 26.9129 19.7746C27.0887 19.9504 27.1875 20.1889 27.1875 20.4375C27.1875 20.6861 27.0887 20.9246 26.9129 21.1004C26.7371 21.2762 26.4986 21.375 26.25 21.375H24.375V23.25C24.375 23.4986 24.2762 23.7371 24.1004 23.9129C23.9246 24.0887 23.6861 24.1875 23.4375 24.1875C23.1889 24.1875 22.9504 24.0887 22.7746 23.9129C22.5988 23.7371 22.5 23.4986 22.5 23.25V21.375H20.625C20.3764 21.375 20.1379 21.2762 19.9621 21.1004C19.7863 20.9246 19.6875 20.6861 19.6875 20.4375C19.6875 20.1889 19.7863 19.9504 19.9621 19.7746C20.1379 19.5988 20.3764 19.5 20.625 19.5H22.5V17.625C22.5 17.3764 22.5988 17.1379 22.7746 16.9621Z"
      />
    </svg>
  );
};

export const Caution = () => {
  return (
    <svg
      viewBox="0 0 30 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
    >
      <path d="M16.8412 1.93622C16.6556 1.6129 16.3879 1.34428 16.0653 1.15748C15.7427 0.970681 15.3765 0.872314 15.0037 0.872314C14.6309 0.872314 14.2647 0.970681 13.942 1.15748C13.6194 1.34428 13.3518 1.6129 13.1662 1.93622L0.309304 23.8118C-0.547571 25.2706 0.479929 27.125 2.1468 27.125H27.8587C29.5256 27.125 30.5549 25.2687 29.6962 23.8118L16.8412 1.93622ZM14.9999 8.37497C16.0031 8.37497 16.7887 9.24122 16.6874 10.2406L16.0312 16.8162C16.0091 17.0745 15.8909 17.3152 15.7 17.4905C15.509 17.6659 15.2592 17.7632 14.9999 17.7632C14.7407 17.7632 14.4908 17.6659 14.2999 17.4905C14.1089 17.3152 13.9907 17.0745 13.9687 16.8162L13.3124 10.2406C13.2889 10.0048 13.3149 9.76665 13.389 9.54153C13.4631 9.31642 13.5835 9.10931 13.7424 8.93355C13.9014 8.7578 14.0955 8.61729 14.312 8.52109C14.5286 8.42488 14.7629 8.37511 14.9999 8.37497ZM15.0037 19.625C15.501 19.625 15.9779 19.8225 16.3295 20.1741C16.6811 20.5258 16.8787 21.0027 16.8787 21.5C16.8787 21.9973 16.6811 22.4742 16.3295 22.8258C15.9779 23.1774 15.501 23.375 15.0037 23.375C14.5064 23.375 14.0295 23.1774 13.6779 22.8258C13.3262 22.4742 13.1287 21.9973 13.1287 21.5C13.1287 21.0027 13.3262 20.5258 13.6779 20.1741C14.0295 19.8225 14.5064 19.625 15.0037 19.625Z" />
    </svg>
  );
};

export const CircleCaution = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6 text-red-600 mr-2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
      />
    </svg>
  );
};
export const MenuArrow = () => {
  return (
    <svg
      className="sub-menu-arrow"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.00002C0 4.86741 0.0526784 4.74024 0.146447 4.64647C0.240215 4.5527 0.367392 4.50002 0.5 4.50002H12.293L9.146 1.35402C9.05211 1.26013 8.99937 1.1328 8.99937 1.00002C8.99937 0.867245 9.05211 0.739908 9.146 0.646021C9.23989 0.552134 9.36722 0.49939 9.5 0.49939C9.63278 0.49939 9.76011 0.552134 9.854 0.646021L13.854 4.64602C13.9006 4.69247 13.9375 4.74764 13.9627 4.80839C13.9879 4.86913 14.0009 4.93425 14.0009 5.00002C14.0009 5.06579 13.9879 5.13091 13.9627 5.19165C13.9375 5.2524 13.9006 5.30758 13.854 5.35402L9.854 9.35402C9.76011 9.44791 9.63278 9.50065 9.5 9.50065C9.36722 9.50065 9.23989 9.44791 9.146 9.35402C9.05211 9.26013 8.99937 9.1328 8.99937 9.00002C8.99937 8.86725 9.05211 8.73991 9.146 8.64602L12.293 5.50002H0.5C0.367392 5.50002 0.240215 5.44734 0.146447 5.35357C0.0526784 5.25981 0 5.13263 0 5.00002Z"
        fill="#091F2C"
      />
    </svg>
  );
};

export const Info = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11C11 10.4477 11.4477 10 12 10Z"
        fill="#1C8BF0"
      />
    </svg>
  );
};

export const Close = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 0.166656C9.47913 0.166656 0.166626 9.47916 0.166626 21C0.166626 32.5208 9.47913 41.8333 21 41.8333C32.5208 41.8333 41.8333 32.5208 41.8333 21C41.8333 9.47916 32.5208 0.166656 21 0.166656ZM21 37.6667C11.8125 37.6667 4.33329 30.1875 4.33329 21C4.33329 11.8125 11.8125 4.33332 21 4.33332C30.1875 4.33332 37.6666 11.8125 37.6666 21C37.6666 30.1875 30.1875 37.6667 21 37.6667ZM28.4791 10.5833L21 18.0625L13.5208 10.5833L10.5833 13.5208L18.0625 21L10.5833 28.4792L13.5208 31.4167L21 23.9375L28.4791 31.4167L31.4166 28.4792L23.9375 21L31.4166 13.5208L28.4791 10.5833Z"
        fill="#091F2C"
      />
    </svg>
  );
};

export const Play = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-4 h-4 ml-[6px]"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const Error = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="red"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
      />
    </svg>
  );
};

export const Copy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-4 h-4 mr-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
      />
    </svg>
  );
};

export const ArrowBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-4 h-4 mr-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
      />
    </svg>
  );
};

export const Trash = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-4 h-4 mr-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
      />
    </svg>
  );
};

export const PriceUp = (props) => {
  return (
    <svg
      className={props.customStyle}
      viewBox="0 0 107 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80 64.9585L93.25 64.9585L85.3 54.3585L58.8 19.0252L53.5 11.9585L48.2 19.0252L21.7 54.3585L13.75 64.9585L27 64.9585L80 64.9585Z"
        stroke="white"
        strokeWidth="13.25"
      />
      <line
        x1="54.8525"
        y1="102.5"
        x2="54.8525"
        y2="58.3333"
        stroke="white"
        strokeWidth="30"
      />
    </svg>
  );
};

export const PriceDown = (props) => {
  return (
    <svg
      className={props.customStyle}
      viewBox="0 0 107 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80 37.2637L93.3004 37.2637L85.2879 47.8797L58.7879 82.9908L53.5 89.9971L48.2121 82.9908L21.7121 47.8797L13.6997 37.2637L27 37.2637L80 37.2637Z"
        stroke="white"
        strokeWidth="13.25"
      />
      <line
        y1="-15"
        x2="43.8889"
        y2="-15"
        transform="matrix(-2.19387e-07 1 1 2.17732e-07 69.8525 0)"
        stroke="white"
        strokeWidth="30"
      />
    </svg>
  );
};

export const Spinner = () => {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
