import {DevConsole} from "../layouts/icons";

export const NavigationObj = () => {
  const headerItems = [
    /*  {
      name: "Creative Tools",
      subNav: [
        {
          name: "Social Image Creator ",
          href: "/image-generator/Analysis-Articles",
        },
        { name: "Email Image Creator ", href: "/email-image-generator" },
        {
          name: "Youtube Thumbnail Creator ",
          href: "/youtube-thumbnail-creator",
        },
      ],
    }, */
    {
      name: "Requests Board",
      href: "https://app.asana.com/0/1205041253588192/1205055807017589",
    },
    {
      name: "Development Projects",
      href: "https://app.asana.com/0/1203925386109921/overview",
    },
    {
      name: "GO Marketing",
      href: "https://gom.digital/",
    },
  ];

  return headerItems;
};

export const FooterNavObj = () => {
  const headerItems = [
    /*   {
      title: "SITE MAP",
      contents: [
        {
          name: "Social Image Creator",
          href: "/image-generator/Analysis-Articles",
        },
        {
          name: "Email Image Creator",
          href: "/email-image-generator",
        },
        {
          name: "Youtube Image Creator",
          href: "/youtube-thumbnail-creatorr",
        },
        {
          name: "Email Image Creator",
          href: "/email-image-generator",
        },
      ],
    }, */
    {
      title: "MORE RESOURCES",
      contents: [
        {
          name: "Branding Guidelines",
          href: "https://gomarkets.atlassian.net/wiki/spaces/BRAN/overview",
        },
        {
          name: "Logo Packs",
          href: "https://lp.gomarkets.com/brand/GO-Markets-Brand-Guidelines.pdf",
        },
        {
          name: "Development Requests Board",
          href: "https://app.asana.com/0/1205041253588192/1205055807017589",
        },
        {
          name: "Confluence",
          href: "https://gomarkets.atlassian.net/wiki/home",
        },
      ],
    },
  ];

  return headerItems;
};

export const OptionItems = () => {
  const items = [
   /*  {
      title: "Design Request",
      description: "Branding, banner ads, animations and website UI",
      url: "https://form.asana.com/?k=cHu_sTWbqExko3gCHAz0pA&d=555449702398463",
      icon: <Pen />,
      color: "hover:text-customBlue hover:border-customBlue",
    }, */
    {
      title: "Development Request",
      description: "New features or products",
      url: "https://form.asana.com/?k=sL9pehQRdFgHP_CEz3JYRg&d=555449702398463",
      icon: <DevConsole />,
      color: "hover:text-primary hover:border-primary",
    },
    /* {
      title: "Email Request",
      description: "Campaigns, client journeys and communications",
      url: "https://form.asana.com/?k=OPJY1EwSMqkbZFq-yzOIlA&d=555449702398463",
      icon: <Envalope />,
      color: "hover:text-customPurple hover:border-customPurple",
    },
    {
      title: "Bug Fix",
      description: "Website technical bugs or content changes",
      url: "https://form.asana.com/?k=Uu_iK8X1tJ3Zsn1GTqrohQ&d=555449702398463",
      icon: <Caution />,
      color: "hover:text-customRed hover:border-customRed",
    }, */
  ];
  return items;
};
